<template>
  <div class="">
    <div class="dr-title ml-4 text-center">
    </div>
  
    <div class="mt-2 mb-4 content-filtros">
      <div class="ml-4" style="max-width: 250px">
        <div class="text-center">
          <v-menu open-on-hover
          offset-y
          transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn v-bind="attrs" v-on="on" > Visualizar por: {{ filtroCampo.text }}</v-btn> -->
              <div style="font-size: 14px; font-weight: 700; color: #757575" class=""  v-bind="attrs" v-on="on"> 
                Visualizado por <span style="color: #1cb4ff">{{ filtroCampo.text}}
              </span>  
              </div>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in filtroSelectCampo" :key="index">
                <v-list-item-title class="text-center" @click="selectCampoTable(item)">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="filtros d-flex ml-5">
        <div class="filtros-graficos">
          <input v-model="dateFilter" type="radio" id="diario" name="filtro" value="day">
          <label for="diario"> diário </label>
        </div>
        <div class="filtros-graficos">
          <input v-model="dateFilter" type="radio" id="semanal" name="filtro" value="week">
          <label for="semanal"> semanal </label>
        </div>
        <div  class="filtros-graficos">
          <input v-model="dateFilter" type="radio" id="mensal" name="filtro" value="month">
          <label for="mensal"> mensal </label>
        </div>
        <div  class="filtros-graficos">
          <v-checkbox class="mt-0 pt-0 ml-4 pl-4"
            v-model="pendentes" :label="`Considerar operações pendentes`" />
        </div>
      </div>
    </div>
    <v-progress-circular  v-if="loader" class="loader" indeterminate color="primary" />
    <hr>

    <!-- GRAFICO -->
    <div class="">
        <grafico :formatLabel="dateFilter" />
    </div>

    <div class="dr-title text-left ml-4 mt-4 mb-2">
      Detalhamento
    </div>

    <div class="table">
      <v-data-table
        :headers="headers"
        :items="result.data || []"
        hide-default-footer
        disable-pagination
        @click:row="visibleLancamento"
        class="table-row"
        >

        <!-- Periodo -->
        <template v-slot:item.Records_groupByMonth="{ item }">
            {{ formatType(item) }}
        </template>

        <!-- Receitas -->
        <template v-slot:item.Records_outcome="{ item }">
            <span style="color: green"> {{ item.Records_outcome * -1 | currencyEqual }}</span>
        </template>

        <!-- Custeio -->
        <template v-slot:item.costing.Records_income="{ item }">
            <span style="color: red">{{ item.costing.Records_income | currencyEqual(true) }}</span>
        </template>

        <!-- Despesas -->
        <template v-slot:item.Records_income="{ item }">
            <span style="color: red">{{ item.Records_income * -1 | currencyEqual }}</span>
        </template>

        <!-- Resultado -->
        <template v-slot:item.Records_result="{ item }">
            <span :style="[{color: item.Records_result <= 0 ? 'green': 'red' }]">  {{ item.Records_result | currencyEqual(true) }} </span> 
        </template>

        <!-- Saldo -->
        <template v-slot:item.Records_balance="{ item }">
           <span :style="[{color: item.Records_balance <= 0 ? 'green': 'red' }]"> {{ item.Records_balance | currencyEqual(true) }}</span>
        </template>

      </v-data-table>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Events from '@/core/service/events'
import moment from 'moment'
moment.locale('pt-br')
import { map } from 'lodash'
const namespaceStore = 'organizzeDespesaReceita'
export default {
  name: 'componentDespesasReceitas',
  components: {
    grafico: () => import('./grafico.vue')
  },
  mounted () {
    const vm = this
    this.dateFilter = 'day'
    this.filtroCampo = this.tiposSelect[1]
    Events.$on('loader:component', (v) => {
      vm.loader = v
    })
  },
  data () {
    return {
      pendentes: true,
      dateFilter: 'month',
      loader: false,
      filtroCampo: {},
      tiposSelect: [
        {text: 'Pagamento', id: 'dt_paid' },
        {text: 'Emissão', id: 'dt_emission' },
        {text: 'Vencimento', id: 'dt_due' },
      ],
      headers: [{ text: 'período(pgamento vencimento)', value: 'Records_groupByMonth' },
      { text: 'Receitas', value: 'Records_outcome'},
      { text: 'Custeio', value: 'costing.Records_income' },
      { text: 'Despesas', value: 'Records_income' },
      { text: 'Resultado', value: 'Records_result' },
      { text: 'Saldo', value: 'Records_balance' }]
    }
  },
  watch: {
    'dateFilter' () {
      this.selectCampoTable()
    },
    'pendentes' () {
      this.selectCampoTable()
    }
  },
  computed: {
    ...mapGetters(namespaceStore, ['result']),
    formatResult () {
      const vm = this
      const res = this.result.data
      const dados = map(res, v => {
        v.text = vm.formatType(v)
        return v
      })
      return dados
    },
    filtroSelectCampo () {
      let atual = this.filtroCampo.id
      const dados = this.tiposSelect
      return dados.filter(e => e.id !== atual)
    }
  },
  methods: {
    visibleLancamento (item) {
      if (this.filtroCampo.id !== 'dt_emission') return
      let dtFormat = 'YYYY-MM-DD HH:mm:ss'
      let dtInit = ''
      let dtEnd = ''

      if (this.dateFilter === 'week') {
        dtInit = moment().weeks(item.Records_Week + 1).year(item.Records_Year).startOf('weeks').format(dtFormat)
        dtEnd = moment().weeks(item.Records_Week + 1).year(item.Records_Year).endOf('weeks').format(dtFormat)
        return this.changeRouterLancamento(dtInit, dtEnd)
      }

      if (this.dateFilter === 'day') {
        dtInit = moment(item.Records_Date).startOf('day').format(dtFormat)
        dtEnd = moment(item.Records_Date).endOf('day').format(dtFormat)
        return this.changeRouterLancamento(dtInit, dtEnd)
      }

      dtInit = moment().month(item.Records_Month -1).year(item.Records_Year).startOf('month').format(dtFormat)
      dtEnd = moment().month(item.Records_Month - 1).year(item.Records_Year).endOf('month').format(dtFormat)

      return this.changeRouterLancamento(dtInit, dtEnd)
    },
    changeRouterLancamento(init, end) {
      this.$router.push({name: 'lancamentos', params: {
        'Records:dtEmission_>=': init,
        'Records:dtEmission_<=': end
      }})
    },
    formatType(v) {
      let type = this.dateFilter
      if (type ==='week') {
        let end = moment().weeks(v.Records_Week + 1).year(v.Records_Year).startOf('weeks').format('DD MMM')
        return moment().weeks(v.Records_Week + 1).year(v.Records_Year).endOf('weeks').format(`[${end} à] DD MMM`)
      }
      if (type ==='day') {
        return moment(v.Records_Date).format('DD/MM/YYYY')
      }
      return `${v.Records_Month}/${v.Records_Year}`
    },
    selectCampoTable (v = false) {
      if (v) {
        this.filtroCampo = v
      }
      
      Events.$emit('loader:component', true)
      // campos para filtros
      this.$emit('input', {
        table: this.filtroCampo.id,
        data: this.dateFilter,
        pendentes: this.pendentes
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dr {
  &-title {
    color: #4a5275;
    font-size: 19px;
  }
}
.content-filtros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.filtros{
  &-graficos {
    input {
      display: none;
    }
    label {
      cursor: pointer;
      padding: 0 15px;
      color: #757575;
      font-size:16px
    }
    input:checked + label {
      color: #1cb4ff;
      font-weight: bold;
      font-size:16px
    }
  }
}
.loader{
  position: absolute;
  top: 150px;
  z-index: 99999;
}
.table::v-deep .v-data-table .v-data-table__wrapper table tbody tr{
  cursor: pointer;
}

</style>